import carouselOne from '/static/images/use-case/by-scenario/live_streaming_for_sports_feature_1.webp'
import carouselTwo from '/static/images/use-case/by-scenario/live_streaming_for_sports_feature_2.webp'
import carouselThree from '/static/images/use-case/by-scenario/live_streaming_for_sports_feature_3.webp'
import carouselFour from '/static/images/use-case/by-scenario/live_streaming_for_sports_feature_4.webp'
import tabOne from '/static/images/use-case/by-scenario/live_streaming_for_sports_scenario_in-stream_switching.webp'
import tabTwo from '/static/images/use-case/by-scenario/live_streaming_for_sports_scenario_multi-network_architecture.webp'
import tabThree from '/static/images/use-case/by-scenario/live_streaming_for_sports_scenario_monitoring_and_analytics.webp'

export const liveStreamingTabs = [
  {
    id: 'switching',
    content: 'In-stream switching',
    picture: tabOne,
    coverTitle: '',
    coverContent: '',
    link: '/',
    cta: '',
    note: `Switching and routing among networks that operates autonomously 24-7 to deliver the best viewing experience for every single viewer.`
  },
  {
    id: 'architecture',
    content: 'Multi-network architecture',
    picture: tabTwo,
    coverTitle: '',
    coverContent: '',
    link: '/',
    cta: '',
    note: `Built-in multi-network structure to empower your video streaming delivery.`
  },
  {
    id: 'analysis',
    content: 'Monitoring and analytics',
    picture: tabThree,
    coverTitle: '',
    coverContent: '',
    link: '/',
    cta: '',
    note: `Know how satisfied your viewers are with your video streaming services with real-time data including playback, performance data, and more.`
  }
]

export const liveStreamingFeatures = [
  {
    title: 'Traffic Spikes',
    content:
      'Live events often experience sudden spikes in viewership, which can overwhelm infrastructure if not properly scaled.',
    imageSrc: carouselOne,
    imageAlt: 'Traffic Spikes'
  },
  {
    title: 'High Latency and Low Quality',
    content: 'High latency can lead to a poor user experience, while low video quality can deter viewers.',
    imageSrc: carouselTwo,
    imageAlt: 'High Latency and Low Quality'
  },
  {
    title: 'Global Reach',
    content:
      'Delivering streaming video content globally requires infrastructure that can handle diverse network conditions and geographical distances.',
    imageSrc: carouselThree,
    imageAlt: 'Global Reach'
  },
  {
    title: 'Reliability and Security',
    content: `Live streaming platforms must ensure secure access to content, especially for paid events.`,
    imageSrc: carouselFour,
    imageAlt: 'Reliability and Security'
  }
]
